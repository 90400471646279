/* eslint-disable-next-line */
/* @import url('./playoff-table.css'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Jost", serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
}

/* Font weight utility classes */
.jost-thin {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.jost-light {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.jost-regular {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.jost-medium {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.jost-semibold {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.jost-bold {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.jost-extrabold {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.jost-black {
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Jost", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
}

.gradient-bg {
  background: linear-gradient(to bottom, #323031, #1d1d1d);
}

.App {
  width: 980px !important;
}

.content-container {
  width: 980px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-bar {
  display: flex;
  justify-content: space-around;
  background-color: #333;
  padding: 10px;
}

.menu-bar li {
  list-style-type: none;
  color: white;
  cursor: pointer;
}

.fixture-list {
  margin-top: 1px;
}

.match-row {
  display: flex;
  justify-content: space-between;
  background-color: #222;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.match-info, .teams, .date-time {
  display: flex;
  flex-direction: column;
}

.score {
  background-color: green;
  padding: 5px;
  border-radius: 1px;
  color: white;
}

.match-row {
  background-color: #1d1d1d;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.additional-info {
  background-color: #323031;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.takimadi {
  display: flex;
  justify-content: space-around;
  width: 600px;
}

.skorbord {
  position: fixed;
}

.team-name {
  text-align: end;
}

.playoff-table * {
  box-sizing: border-box;
}

.playoff-table {
  font-family: sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  font-weight: 400;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #0d0d0d;
}

.playoff-table .playoff-table-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  padding: 20px;
}

.playoff-table .playoff-table-tour {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  position: relative;
}

.playoff-table .playoff-table-pair {
  position: relative;
}

.playoff-table .playoff-table-pair:before {
  content: '';
  position: absolute;
  top: 27px;
  right: -12px;
  width: 12px;
  height: 1px;
  background-color: red;
}

.playoff-table .playoff-table-pair:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 1000px;
  background-color: #0d0d0d;
  right: -12px;
  z-index: 1;
}

.playoff-table .playoff-table-pair:nth-child(even):after {
  top: 28px;
}

.playoff-table .playoff-table-pair:nth-child(odd):after {
  bottom: 28px;
}

.playoff-table .playoff-table-pair-style {
  border: 1px solid #cccccc;
  background-color: white;
  width: 160px;
  margin-bottom: 20px;
}

.playoff-table .playoff-table-group {
  padding-right: 11px;
  padding-left: 10px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
}

.playoff-table .playoff-table-group .playoff-table-pair-style:last-child {
  margin-bottom: 0px;
}

.playoff-table .playoff-table-group:after {
  content: '';
  position: absolute;
  top: 28px;
  bottom: 29px;
  right: 0px;
  width: 4px;
  background-color: rgb(215, 201, 201);
}

.playoff-table .playoff-table-group:last-child {
  margin-bottom: 0;
}

.playoff-table .playoff-table-left-player,
.playoff-table .playoff-table-right-player {
  min-height: 26px;
  padding: 3px 5px;
}

.playoff-table .playoff-table-left-player {
  border-bottom: 1px solid #cccccc;
}

.playoff-table .playoff-table-left-player:before {
  content: '';
  position: absolute;
  bottom: 27px;
  left: -12px;
  width: 12px;
  height: 1px;
  background-color: red;
}

.playoff-table .playoff-table-right-player {
  margin-top: -1px;
  border-top: 1px solid #cccccc;
}

.playoff-table .playoff-table-third-place {
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 100px;
}

.playoff-table .playoff-table-third-place .playoff-table-left-player:before {
  display: none;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-group {
  padding-left: 0;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-left-player:before {
  display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-group:after {
  display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-pair:after,
.playoff-table .playoff-table-tour:last-child .playoff-table-pair:before {
  display: none;
}

.playoff-table .playoff-table-winner {
  background-color: #1a1a1a;
  border: 2px solid #ffd700;
  width: 210px;
  margin-bottom: 0;
}

.playoff-table .playoff-table-champion {
  padding: 10px;
  color: white;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #1a1a1a, #2d2d2d);
}

.playoff-table .playoff-table-champion img {
  filter: drop-shadow(0 0 4px rgba(255, 215, 0, 0.5));
}

/*# sourceMappingURL=playoff-table.css.map */